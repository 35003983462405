<template>
    <DataTable editMode="row" v-model:editingRows="editingRows" responsiveLayout="stack" :value="dto"
        selectionMode="single" showGridlines v-model:selection="itemSelecionado">
        <template #header>
            <strong style="font-size: 14px;">Parcelas</strong>
        </template>

        <template #empty>
            Nenhum registro encontrado.
        </template>

        <template #loading>
            Carregando... Por favor, aguarde.
        </template>

        <Column field="data" header="Data">
          <template #body="{ data, field }">
            {{ formatDateCustom(data.dataVencimento, "DD/MM/YYYY") }}
          </template>
          <template #editor="{ data, field }">
            <Calendar v-model="data[field]" class="w-full"/>
          </template>
        </Column>

        <Column field="percentual" header="Percentual">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field]) }} {{!!data.valorTotal?"%":""}}
            </template>
            <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" class="w-full" locale="pt-BR" :max="9999999" :minFractionDigits="2"
                    :maxFractionDigits="2" />
            </template>
        </Column>

        <Column field="valorTotal" header="Valor">
          <template #body="{ data, field }">
            {{data.valorTotal!=null?"R$":""}} {{ formatDecimal(data[field]) }}
          </template>
        </Column>

        <Column v-if="editavel" header="Ações" style="width: 9rem;" class="centralizar-titulo-tabela">
            <template #body="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-plus" aria-label="Novo"
                        @click="editar(data)" v-if="!data.percentual" title="Novo"></Button>
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-pencil" aria-label="Editar"
                        @click="editar(data)" v-if="data.percentual" title="Editar"></Button>
                    <Button class="p-button-rounded p-button-danger flex-none mr-2" icon="pi pi-trash" aria-label="Excluir"
                        @click="excluir(index)" v-if="data.percentual" title="Excluir"></Button>
                </div>
            </template>
            <template #editor="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-save" aria-label="Editar"
                        @click="salvar(data, index, ccv)" title="Salvar"></Button>
                    <Button class="p-button-rounded p-button-danger flex-none mr-2" icon="pi pi-times" aria-label="Editar"
                        @click="cancelar()" title="Cancelar"></Button>
                </div>
            </template>
        </Column>
        <template #footer>
          {{ somaLinhas(data,ccv) }}
        </template>

    </DataTable>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';

export default {
    props: {
        editavel: {
            type: Boolean,
            required: true
        },

        dto: {
            type: Object,
            required: true
        },

        ccv: {
          type: Object,
          required: true
        },

        totalAdiantamento: {
          type: Number,
          required: true
        },

        campoInvalido: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            editingRows: [],

            indicadorFiltro: [],
            indicadores: [],

            operacaoFiltro: [],
            operacoes: [],

            itemSelecionado: {},
            totalAdiantamento: 0
        }
    },

    created() {
        this.setup();
    },

    updated() {
        this.setup();
    },

    methods: {

        setup() {
            if (!this.dto) {
                this.dto = [];
            }

            if (this.editavel) this.adicionarLinha();
        },

        editar(registro) {
            const registroAux = Object.assign({}, registro);
            this.editingRows = [registroAux];
        },

        validarCamposObrigatorios(registro) {
            const campos = [];

            if (!registro.data) {
                campos.push("Data");
                this.campoInvalido.data = true;
            }
            else {
                this.campoInvalido.data = false;
            }

            if (!registro.percentual) {
                campos.push("Percentual");
                this.campoInvalido.percentual = true;
            }
            else {
                this.campoInvalido.percentual = false;
            }

            if (campos.length > 0) {
                const toast = {
                    severity: 'warn',
                    summary: 'Item de Composição de Valor',
                    detail: `Por favor, preencher o campo ${campos[0]}`,
                    life: 7500
                }

                if (campos.length > 1) {
                    toast.detail = "Por favor, preencher os campos: ";
                    toast.detail += campos.join(", ");

                    const indice = toast.detail.lastIndexOf(", ");
                    toast.detail = `${toast.detail.substring(0, indice)} e ${toast.detail.substring(indice + 1, toast.detail.length)}.`;
                }

                this.$toast.add(toast);
                return true;
            }

            return false;
        },

        salvar(registro, indice, ccv) {
            if (!registro) return;
            if (this.validarCamposObrigatorios(registro)) return;
              let valorAdiantamento = 0;
              if (!!ccv.adiantamentos) {
                let vl = 0;
                for(let adiantamento of ccv.adiantamentos){
                  vl = vl + adiantamento.valorTotal;
                }
                valorAdiantamento = vl;
            }
            this.totalAdiantamento = valorAdiantamento;

            let obterDolar = 1; //Integrar com Datasul
            let valorTotal = (ccv.modalidadePrecificacao == 'A_FIXAR' ?
                ccv.quantidade * ccv.valorFaturamentoSimbolico/60 * ccv.unidade.fatorConversao :
                ccv.modalidadePrecificacao == 'FIXO' ?
                    ccv.quantidade * ccv.precoUnitario / ccv.fatorConversao * ccv.unidade.fatorConversao :
                    ccv.quantidade * ccv.precoUnitario * obterDolar * ccv.unidade.fatorConversao / (!ccv.unidadePrecoFixo ?
                        1 : ccv.unidadePrecoFixo.fatorConversao)) - valorAdiantamento;

            registro.valorTotal = valorTotal * registro.percentual / 100;
            this.dto[indice] = registro;
            this.dto.sort((objA, objB) => { return objA["data"] - objB["data"]; });
            this.editingRows = [];
            this.adicionarLinha();
        },

        excluir(indice) {
            this.dto.splice(indice, 1);
            this.adicionarLinha();
        },

        cancelar() {
            if (!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
            this.adicionarLinha();
            this.campoInvalido.indicadorValor = false;
            this.campoInvalido.operacao = false;
            this.campoInvalido.ordem = false;
        },

        adicionarLinha() {
            const indice = this.dto.findIndex(item => {
                return !item.data || !item.percentual || !item.valorTotal;
            });

            if (indice < 0) {
                this.dto.push({});
            }
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            if (value!=null) return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(date, format) {
          return Formatacao.formatDateCustom(date, format);
        },

        somaLinhas(){
          let soma = 0;
          for(let c of this.dto){
            soma = soma + (c.valorTotal != undefined ? c.valorTotal : 0);
          }
          return "Valor Total: R$ " + this.formatDecimal(soma, 2, 2);
        }

    }
}
</script>

<style>
.centralizar-titulo-tabela {
    text-align: center !important;
}

.centralizar-titulo-tabela .p-column-header-content {
    display: inline;
}
</style>
